import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core';
import s from './FontAwesomeIcon.module.scss';

// Pass either iconClass (e.g. "fas fa-user") or style and name seperately (e.g. "solid" and "user")
const FontAwesomeIcon = ({
    iconClass = '',
    style = '',
    name = '',
    className = '',
}) => {
    const iconClasses = iconClass.split(' ');
    const nameIndex = iconClasses.findIndex((i) => i.startsWith('fa-'));
    const restSplit = [...iconClasses];
    restSplit.splice(nameIndex, 1);
    const styleIndex = restSplit.findIndex((i) => i.startsWith('fa'));
    const iconName = name ? name : iconClasses[nameIndex];
    const iconStyle = style ? style : restSplit[styleIndex];
    restSplit.splice(styleIndex, 1);

    if(!iconStyle || !iconName) {
        console.error(`fontawesome icon style or name is missing - iconClass ${iconClass}, style ${iconStyle}, name ${iconName}`);
        return null;
    }

    const styles = ['fas', 'fat', 'fal', 'far', 'fab'];
    if(!styles.includes(iconStyle)) {
        console.error(`fontawesome style ${iconStyle} doesn't exist or isn't imported`);
        return null;
    }

    const classes = classNames(
        className,
        restSplit.join(' '),
    );

    return (
        <Icon
            iconName={iconName.replace('fa-', '')}
            prefix={iconStyle}
            className={classes}
        />
    );
};

const Icon = ({
    prefix = '',
    iconName = '',
    className = '',
}) => {
    if(!prefix || !iconName) {
        return null;
    }

    const lookup = icon({prefix: prefix, iconName: iconName});
    if(!lookup) {
        console.error(`fontawesome icon is undefiend - prefix ${prefix}, iconName ${iconName}`);
        return null;
    }
    return (
        <FAIcon
            icon={lookup}
            className={className}
        />
    );
};

Icon.propTypes = {
    prefix: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default FontAwesomeIcon;
